import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Header from "./components/Header";
import Banner from "./images/contact_us.jpg";
import { Footer } from "./components/Footer";

const validationSchema = Yup.object({
  companyName: Yup.string()
    .trim()
    .strict()
    .min(3, "Company Name must be at least 3 characters")
    .max(50, "Company Name cannot exceed 50 characters")
    .required("Company Name is required"),
  contactNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Contact Number must be a 10-digit number")
    .required("Contact Number is required"),
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
  enquiry: Yup.string()
    .trim()
    .min(10, "Enquiry must be at least 10 characters")
    .max(500, "Enquiry cannot exceed 500 characters")
    .required("Enquiry is required"),
  recaptcha: Yup.string().required("Please complete the reCAPTCHA"),
});

export const Contact = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const formik = useFormik({
    initialValues: {
      companyName: "",
      contactNumber: "",
      email: "",
      enquiry: "",
      recaptcha: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        await axios.post("https://nsarcus.com/api/submit_form.php", values);

        setShowThankYou(true);
        formik.resetForm();
      } catch (error) {
        console.error("Error sending enquiry:", error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });
  setTimeout(() => {
    setShowThankYou(false);
  }, 5000);

  return (
    <>
      {showThankYou && (
        <div className="thank-you-overlay">
          <div className="thank-you-popup">
            <button
              className="close-button"
              onClick={() => setShowThankYou(false)}
            >
              &times;
            </button>
            <h3>Thank you for submitting the form!</h3>
          </div>
        </div>
      )}
      <Header />

      <section id="banner" className="p-0">
        <div className="banner">
          <div className="homcolrt">
            <img src={Banner} alt="Contact Us" />
          </div>
          <div className="container">
            <div className="homcolft position-absolute position-right left">
              <span className="colorborder">
                <em></em>
              </span>
              <h2
                data-aos="fade-down"
                data-aos-offset="100"
                data-aos-easing="ease-in-sine"
                data-aos-once="true"
                data-aos-duration="500"
              >
                <span>
                  We Are Here <strong>For You.</strong>
                </span>
              </h2>
            </div>
          </div>
        </div>
      </section>
      <div className="contactfooter">
        <Footer />
        <div className="contactrow conatcat_us_form">
          <div className="row">
            <div className="container">
              <div className="web-container">
                <form onSubmit={formik.handleSubmit}>
                  <div className="contactform">
                    <h2>CONTACT FORM</h2>
                    <ul>
                      <li>
                        <input
                          type="text"
                          placeholder="Company Name *"
                          name="companyName"
                          value={formik.values.companyName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.companyName
                              ? "error-input"
                              : formik.values.companyName
                              ? "input-filled"
                              : ""
                          }
                        />
                        {formik.touched.companyName &&
                        formik.errors.companyName ? (
                          <div>
                            <span className="error-message" flow="left">
                              {formik.errors.companyName}
                            </span>
                          </div>
                        ) : null}
                      </li>
                      <li>
                        <input
                          type="tel"
                          placeholder="Contact Number *"
                          name="contactNumber"
                          value={formik.values.contactNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.contactNumber
                              ? "error-input"
                              : formik.values.contactNumber
                              ? "input-filled"
                              : ""
                          }
                        />
                        {formik.touched.contactNumber &&
                        formik.errors.contactNumber ? (
                          <div>
                            <span className="error-message" flow="left">
                              {formik.errors.contactNumber}
                            </span>
                          </div>
                        ) : null}
                      </li>
                      <li>
                        <input
                          type="email"
                          placeholder="Email *"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.email
                              ? "error-input"
                              : formik.values.email
                              ? "input-filled"
                              : ""
                          }
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div>
                            <span className="error-message" flow="left">
                              {formik.errors.email}
                            </span>
                          </div>
                        ) : null}
                      </li>
                      <li>
                        <input
                          type="text"
                          placeholder="Enquiry"
                          name="enquiry"
                          value={formik.values.enquiry}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.enquiry
                              ? "error-input"
                              : formik.values.enquiry
                              ? "input-filled"
                              : ""
                          }
                        />
                        {formik.touched.enquiry && formik.errors.enquiry ? (
                          <div>
                            <span className="error-message" flow="left">
                              {formik.errors.enquiry}
                            </span>
                          </div>
                        ) : null}
                      </li>
                      <li
                        className={`_recapthaa  ${
                          formik.errors.recaptcha
                            ? "error-input"
                            : formik.values.recaptcha
                            ? "input-filled"
                            : ""
                        }`}
                      >
                        <div className="_recaptaa_cnt">
                          <ReCAPTCHA
                            sitekey="6LdnGp8pAAAAACeXXtiWmytcP0l1b2hwwxTqh_gR"
                            onChange={(token) =>
                              formik.setFieldValue("recaptcha", token)
                            }
                          />
                          {formik.touched.recaptcha &&
                            formik.errors.recaptcha && (
                              <span className="error-message" flow="left">
                                {formik.errors.recaptcha}
                              </span>
                            )}
                        </div>
                      </li>
                    </ul>
                    <div className="text-center d-flex justify-content-center">
                      <span className="btn10">
                        <input
                          type="submit"
                          value={isSubmitting ? "Submitting..." : "Submit"}
                          disabled={isSubmitting}
                        />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright d-block">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <p>&copy; 2023 ns arcus | all rights reserved. </p>
                  </div>
                  <div className="col-md-6 col-xs-12 text-right d-flex justify-content-end">
                    <p>
                      <a
                        href="https://triverseadvertising.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        design:triverse
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
