import { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import "./index.css";

const FormPopUp = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    emailAddress: "",
    location: "",
    recaptcha: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    mobileNumber: "",
    emailAddress: "",
    location: "",
    recaptcha: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setShowThankYou(false); // Reset thank you message when reopening the popup
  };

  const handleClose = () => {
    setIsPopupOpen(false);
    setShowThankYou(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (formData.location.trim() === "") {
      newErrors.location = "Location is required";
      isValid = false;
    }

    if (formData.mobileNumber.trim() === "") {
      newErrors.mobileNumber = "Mobile number is required";
      isValid = false;
    } else if (!/^[0-9]{10}$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber =
        "Invalid mobile number format (should be 10 digits)";
      isValid = false;
    }

    if (formData.emailAddress.trim() === "") {
      newErrors.emailAddress = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.emailAddress)) {
      newErrors.emailAddress = "Invalid email format";
      isValid = false;
    }

    if (formData.recaptcha.trim() === "") {
      newErrors.recaptcha = "reCAPTCHA is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);

      const url = "https://nsarcus.com/api/dealer_enquiry.php";
      let fData = new FormData();
      fData.append("user_name", formData.name);
      fData.append("user_phone", formData.mobileNumber);
      fData.append("user_email", formData.emailAddress);
      fData.append("user_location", formData.emailAddress);
      fData.append("utm_url", window.location.href);
      fData.append("recaptcha", formData.recaptcha);

      axios
        .post(url, fData)
        .then((response) => {
          if (response.data.success) {
            setShowThankYou(true);
            setFormData({
              name: "",
              mobileNumber: "",
              emailAddress: "",
              location: "",
              recaptcha: "",
            });
          }
          setIsSubmitting(false);
          console.log("Api response", response.data);
        })
        .catch((error) => {
          alert(error);
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (showThankYou) {
      const timer = setTimeout(() => {
        setShowThankYou(false);
        setIsPopupOpen(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showThankYou]);

  return (
    <>
      <div className="popUp_form overflow-hidden">
        {!isPopupOpen && (
          <div className="enquirebtn premixEnq">
            <a onClick={togglePopup}>
              <span>Dealer Enquire</span>
            </a>
          </div>
        )}
        <div
          className={`enq_form premix_enq_from ${isPopupOpen ? "active" : ""}`}
        >
          <div className="formcont">
            <div className="contact_mobile_form">
              <button className="close-btn" onClick={handleClose}>
                &times;
              </button>
              <h4>Request a call back</h4>
              <form onSubmit={handleSubmit}>
                <ul>
                  <li>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name*"
                      value={formData.name}
                      onChange={handleChange}
                      className={
                        errors.name
                          ? "error-input"
                          : formData.name
                          ? "input-filled"
                          : ""
                      }
                    />
                  </li>
                  <li>
                    <input
                      type="tel"
                      name="mobileNumber"
                      placeholder="Mobile*"
                      value={formData.mobileNumber}
                      onChange={handleChange}
                      className={
                        errors.mobileNumber
                          ? "error-input"
                          : formData.mobileNumber
                          ? "input-filled"
                          : ""
                      }
                    />
                  </li>
                  <li>
                    <input
                      type="email"
                      name="emailAddress"
                      placeholder="Email*"
                      value={formData.emailAddress}
                      onChange={handleChange}
                      className={
                        errors.emailAddress
                          ? "error-input"
                          : formData.emailAddress
                          ? "input-filled"
                          : ""
                      }
                    />
                  </li>
                  <li>
                    <input
                      type="text"
                      name="location"
                      placeholder="Location*"
                      value={formData.location}
                      onChange={handleChange}
                      className={
                        errors.location
                          ? "error-input"
                          : formData.location
                          ? "input-filled"
                          : ""
                      }
                    />
                  </li>
                  <li className="_recapthaa">
                    <ReCAPTCHA
                      sitekey="6LdnGp8pAAAAACeXXtiWmytcP0l1b2hwwxTqh_gR"
                      onChange={(token) =>
                        setFormData({ ...formData, recaptcha: token })
                      }
                    />
                  </li>
                </ul>
                <button
                  type="submit"
                  className={`enq_btn btn10 ${isSubmitting ? "disabled" : ""}`}
                  disabled={isSubmitting}
                >
                  <div>
                    <span>{isSubmitting ? "Submitting..." : "Submit"}</span>
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showThankYou && (
        <div className="full-screen-thank-you col-lg-8 col-md-10 col-12 ">
          <p>
            Thank you!
            <br /> Your enquiry has been submitted.
          </p>
        </div>
      )}
    </>
  );
};

export default FormPopUp;
