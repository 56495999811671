import axios from "axios";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha"; // Import the reCAPTCHA component

export const Index = ({ onHover }) => {
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    emailAddress: "",
    recaptcha: "", // Add recaptcha field to formData state
  });

  const [errors, setErrors] = useState({
    name: "",
    mobileNumber: "",
    emailAddress: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const handleMouseEnter = () => {
    onHover(true); // Inform parent component about hover state change
  };

  const handleMouseLeave = () => {
    onHover(false); // Inform parent component about hover state change
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (formData.mobileNumber.trim() === "") {
      newErrors.mobileNumber = "Mobile number is required";
      isValid = false;
    } else if (!/^[0-9]{10}$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber =
        "Invalid mobile number format (should be 10 digits)";
      isValid = false;
    }

    if (formData.emailAddress.trim() === "") {
      newErrors.emailAddress = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.emailAddress)) {
      newErrors.emailAddress = "Invalid email format";
      isValid = false;
    }

    if (formData.recaptcha.trim() === "") {
      newErrors.recaptcha = "reCAPTCHA is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);

      const url = "https://nsarcus.com/api/submit_form.php";
      let fData = new FormData();
      fData.append("user_name", formData.name);
      fData.append("user_phone", formData.mobileNumber);
      fData.append("user_email", formData.emailAddress);
      fData.append("utm_url", window.location.href);
      fData.append("recaptcha", formData.recaptcha); // Include reCAPTCHA token

      axios
        .post(url, fData)
        .then((response) => {
          if (response.data.success) {
            setShowThankYou(true);
            setFormData({
              name: "",
              mobileNumber: "",
              emailAddress: "",
              recaptcha: "", // Reset recaptcha field after successful submission
            });
          }
          setIsSubmitting(false);
        })
        .catch((error) => {
          alert(error);
          setIsSubmitting(false);
        });
    }
  };

  setTimeout(() => {
    setShowThankYou(false);
  }, 5000);

  return (
    <>
      {showThankYou && (
        <div className="thank-you-overlay">
          <div className="thank-you-popup">
            <button
              className="close-button"
              onClick={() => setShowThankYou(false)}
            >
              &times;
            </button>
            <h3>Thank you for submitting the form!</h3>
          </div>
        </div>
      )}
      <div className="enq_form">
        <div
          className="contact_mobile_form"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <h4>Request a call back</h4>
          <form onSubmit={handleSubmit}>
            <ul>
              <li>
                <input
                  type="text"
                  name="name"
                  placeholder="Name*"
                  value={formData.name}
                  onChange={handleChange}
                  className={
                    errors.name
                      ? "error-input"
                      : formData.name
                      ? "input-filled"
                      : ""
                  }
                />
              </li>
              <li>
                <input
                  type="tel"
                  name="mobileNumber"
                  placeholder="Mobile*"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  className={
                    errors.mobileNumber
                      ? "error-input"
                      : formData.mobileNumber
                      ? "input-filled"
                      : ""
                  }
                />
              </li>
              <li>
                <input
                  type="email"
                  name="emailAddress"
                  placeholder="Email*"
                  value={formData.emailAddress}
                  onChange={handleChange}
                  className={
                    errors.emailAddress
                      ? "error-input"
                      : formData.emailAddress
                      ? "input-filled"
                      : ""
                  }
                />
              </li>
              {/* Add reCAPTCHA component */}
              <li className="_recapthaa">
                <ReCAPTCHA
                  sitekey="6LdnGp8pAAAAACeXXtiWmytcP0l1b2hwwxTqh_gR"
                  onChange={(token) =>
                    setFormData({ ...formData, recaptcha: token })
                  }
                />
              </li>
            </ul>

            <button
              type="submit"
              className={`enq_btn btn10 ${isSubmitting ? "disabled" : ""}`}
              disabled={isSubmitting}
            >
              <div>
                <span>{isSubmitting ? "Submitting..." : "Submit"}</span>
              </div>
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
